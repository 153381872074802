<template>
  <div>
    <!--begin::Head-->
    <div class="kt-login__head">
      <span class="kt-login__signup-label">{{
        $t("AUTH.GENERAL.NO_ACCOUNT")
      }}</span
      >&nbsp;&nbsp;
      <router-link
        class="kt-link kt-login__signup-link"
        :to="{ name: 'register' }"
      >
        {{ $t("AUTH.GENERAL.SIGNUP_BUTTON") }}
      </router-link>
      |
      <router-link
        class="kt-link kt-login__signup-link"
        :to="{ name: 'login' }"
      >
        Login!
      </router-link>
    </div>
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3 class="mt-sm-3">Forgot Password</h3>
        </div>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-form-group label="">
            <b-form-input
              v-model="$v.form.email.$model"
              :state="validateState('email')"
              aria-describedby="input-1-live-feedback"
              placeholder="Enter email address"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback">
              Email is required and a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group class="my-4">
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="mx_config.recaptcha.v2.site_key"
              :loadRecaptchaScript="true"
              @verify="reCaptcha_verify"
              @expired="reCaptcha_expired"
            >
            </vue-recaptcha>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <span>&nbsp;</span>
            <button
              type="submit"
              id="kt_submit"
              class="btn btn-primary btn-elevate kt-login__btn-primary"
            >
              Submit
            </button>
          </div>
          <!--end::Action-->
        </b-form>
        <!--end::Form-->

        <!--begin::Divider-->
        <div class="kt-login__divider">
          <div class="kt-divider">
            <span></span>
            <span>OR LOGIN WITH</span>
            <span></span>
          </div>
        </div>
        <!--end::Divider-->

        <!--begin::Options-->
        <div class="kt-login__options">
          <a :href="sso_link('facebook')" class="btn btn-primary kt-btn">
            <i class="fab fa-facebook-f"></i>
            Facebook
          </a>

          <a :href="sso_link('twitter')" class="btn btn-info kt-btn">
            <i class="fab fa-twitter"></i>
            Twitter
          </a>

          <a :href="sso_link('google')" class="btn btn-danger kt-btn">
            <i class="fab fa-google"></i>
            Google
          </a>
        </div>
        <!--end::Options-->
      </div>
      <!--end::Signin-->
    </div>
    <!--end::Body-->
  </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
  right: 8px;
}
</style>

<script>
import ApiService from "@/common/api.service";
import VueRecaptcha from "vue-recaptcha";
import { LOGIN_SSO } from "@/store/auth.module";
import { API_URL } from "@/common/config.js";
import { email, minLength, required } from "vuelidate/lib/validators";
import { notif, ApiURL, util } from "@/common/mixins/general.js";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin, notif, ApiURL, util],
  name: "login",
  components: { VueRecaptcha },
  data() {
    return {
      form: {
        email: "",
        reCaptcha: false
      },
      reCaptcha: false,
      apiurl: API_URL
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    /**
     * --------------------------------------------------
     * submit data forgot password
     * --------------------------------------------------
     */
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        if (!this.reCaptcha || !this.form.reCaptcha) {
          this.mx_swal("Please check reCaptcha!");
          return;
        } else {
          // set spinner to submit button
          const submitButton = document.getElementById("kt_submit");
          submitButton.classList.add(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
          );

          ApiService.post(this.api.auth.forgot, this.form)
            .then(({ data }) => {
              this.mx_swal(data.message, "", "success");
              this.form.token = false;
              this.form.email = "";
              this.$nextTick(() => {
                this.$v.$reset();
              });
            })
            .catch(({ response }) => {
              this.mx_swal(response.data.message);
            })
            .finally(() => {
              this.resetRecaptcha();
              submitButton.classList.remove(
                "kt-spinner",
                "kt-spinner--light",
                "kt-spinner--right"
              );
            });
        }
      }
    },

    /**
     * --------------------------------------------------------------
     * generate login sosmed
     * --------------------------------------------------------------
     */
    sso_link: function(sosmed) {
      return `${this.apiurl}/auth/sso/${sosmed}`;
    },

    /**
     * --------------------------------------------------------------
     * verify token login valid or tidak
     * --------------------------------------------------------------
     */
    sso_verify: function() {
      ApiService.setHeader();
      ApiService.get(this.api.auth.verify)
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .catch(({ response }) => {
          this.mx_swal(response.data.message);
        });
    },

    /**
     * --------------------------------------------------------------
     * verify captcha jika sukses
     * --------------------------------------------------------------
     */
    reCaptcha_verify: function(token) {
      this.reCaptcha = true;
      this.form.reCaptcha = token;
    },

    /**
     * --------------------------------------------------------------
     * handle captcha jika expired
     * --------------------------------------------------------------
     */
    reCaptcha_expired: function() {
      location.reload();
    },

    /**
     * --------------------------------------------------------------
     * reset reCaptcha directly
     * --------------------------------------------------------------
     */
    resetRecaptcha: function() {
      this.$refs.recaptcha.reset();
    },
    reset() {
      this.$v.form.$reset();
    }
  },
  mounted() {
    /**
     * ------------------------------------------------
     * proses untuk yg login menggunakan sso
     * ------------------------------------------------
     */
    let query = this.$route.query;
    if (typeof query.token !== "undefined") {
      if (query.token !== "false") {
        this.$store.dispatch(LOGIN_SSO, query.token).then(() => {
          this.sso_verify();
        });
      } else {
        if (query.error === "Email tidak ditemukan") {
          this.mx_swal(query.error);
        }
      }
    }
  }
};
</script>
